<template>
  <div class="timer" :class="classes">
    <div v-if="isEventFinished" class="item-container item-container__finished">
      <div class="item-container__header">{{ $t('mainPage.eventTimer.end') }}</div>
      <div class="item-container__subtext">{{ formattedDate }}</div>
    </div>
    <div v-for="elTime in timeArray" v-else :key="elTime.key" class="item-container">
      <p class="item-container__header">{{ elTime.key }}</p>
      <p class="item-container__subtext">{{ getDescriptionTime(elTime) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IEventTimerProps, ITimeUnit, TDescriptionDate } from './EventTimer.types';
import { createTimeArray } from './EventTimer.data';
import { useFormattedDate } from '~/features/mainPage/composables/useFormattedDate';

const { t } = useI18n();

const props = defineProps<IEventTimerProps>();
const { dateTime } = toRefs(props);

const { timerData } = useTimer(dateTime);

const { formattedDate } = useFormattedDate(dateTime);

const isEventFinished = computed(() => timerData.value.rawTime <= 0);

const timeArray = computed<ReturnType<typeof createTimeArray>>(() => createTimeArray(timerData.value));

const calculateDescriptionSign = (item: ITimeUnit, key: TDescriptionDate): string | undefined => {
  const description = item.description[key];
  return typeof description === 'function' ? description(+item.key, item.type, t) : description;
};

const getDescriptionTime = (item: ITimeUnit) => {
  return calculateDescriptionSign(item, 'text');
};

const classes = computed(() => ({
  'timer--dark': props.theme === 'dark',
  'timer--sharp-top': props.sharpTop,
}));
</script>
<style scoped lang="scss" src="./EventTimer.scss"></style>
