interface IFormattedDate {
  formattedDate: ComputedRef<string>;
}

/**
 * Реактивная форматированная дата в формате dd.mm.yyy
 * @param { string | number } dateTime - Дата в unix формате
 * @returns { IFormattedDate } - Реактивная дата в формате dd.mm.yyy
 */
export const useFormattedDate = (dateTime: Ref<string | number | undefined>): IFormattedDate => {
  const formattedDate = computed(() => {
    if (dateTime.value === undefined) return '';

    const date = new Date(dateTime.value);
    const yyyy = date.getFullYear();
    const mm = (date.getMonth() + 1).toString().padStart(2, '0');
    const dd = date.getDate().toString().padStart(2, '0');

    return dd + '.' + mm + '.' + yyyy;
  });

  return { formattedDate };
};
