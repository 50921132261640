import type { TArgsValue, ITimeUnit } from './EventTimer.types';
import { getDateTitle } from '~/utils/timer.utils';

export const createTimeArray = ({ days, hours, minutes, seconds }: TArgsValue): ITimeUnit[] => {
  const timeArray: ITimeUnit[] = [];
  const types = ['days', 'hours', 'minutes', 'seconds'];

  for (const type of types) {
    const value = type === 'days' ? days : type === 'hours' ? hours : type === 'minutes' ? minutes : seconds;
    if (value && !timeArray.some((time) => time.type === type)) {
      timeArray.push({
        description: { text: getDateTitle },
        key: value,
        type: type as ITimeUnit['type'],
      });
    }
  }

  return timeArray;
};
